import { AuthenticationActions } from '$/app/authentication/authentication.actions';
import { AuthenticationService } from '$/app/authentication/authentication.service';
import { AppInfo } from '$/app/services';
import { GeolocationService } from '$/app/services/utils/geolocation.service';
import { SharedModule } from '$/app/shared/shared.module';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { Color } from '$/config/colors';
import { environment } from '$/environments/environment';
import { computedWith } from '$/lib/signals/computed-with';
import { AlcGeolocation } from '$shared/facility-settings/geolocation.types';
import { Logger } from '$shared/logger';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  viewChild
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Store } from '@ngrx/store';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings
} from 'capacitor-native-settings';
import { find } from 'lodash';

type State =
  | 'location-disabled'
  | 'location-denied'
  | 'location-error'
  | 'outside-geofence';

// TODO: What should we do if this page is simply reloaded?
// It appears that auth doesn't happen, and there's no facility, so... the page is kinda dysfunctional
@Component({
  selector: 'alc-geofence-violation-page',
  imports: [SharedModule],
  templateUrl: './geofence-violation.page.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AlcGeofenceViolationPage {
  private readonly store = inject(Store);
  private readonly auth = inject(AuthenticationService);
  private readonly geolocationService = inject(GeolocationService);

  private readonly user = this.store.selectSignal(UserSelectors.selectUser);

  private readonly currentFacility = computedWith(
    this.store.selectSignal(UserSelectors.selectFacilityId),
    (facilityId) => find(this.facilities(), { id: facilityId() })
  );

  protected readonly platform = AppInfo.deviceInfo.platform;

  protected readonly mapContainer = viewChild('mapContainer', {
    read: ElementRef
  });

  protected readonly facilities = this.store.selectSignal(
    UserSelectors.selectUserFacilities
  );

  protected readonly state = computed((): State => {
    switch (this.geolocationService.locationServicesState()) {
      case 'disabled':
        // On the web, we treat "disabled" as "denied", unifying the two states in the UI
        return AppInfo.deviceInfo.platform === 'web'
          ? 'location-denied'
          : 'location-disabled';

      case 'denied':
        return 'location-denied';

      case 'prompt':
      case 'granted':
        break;

      default:
        Logger.error('Unknown location services state', {
          state: this.geolocationService.locationServicesState()
        });
        break;
    }

    if (this.geolocationService.geolocation().status !== 'success') {
      return 'location-error';
    }

    return 'outside-geofence';
  });

  protected readonly message = computed(() => {
    switch (this.state()) {
      case 'outside-geofence':
        return {
          primary: 'You are too far from your care community.',
          secondary: 'Return to your care community and then try again.'
        };

      case 'location-error':
        return {
          primary: 'Something went wrong while determining your location.',
          secondary:
            'Please try again or contact Alcomy support if the problem persists'
        };

      case 'location-disabled':
        return {
          primary:
            'Alcomy needs to know your location for certain security features to work',
          secondary:
            'Click "Location Settings" below to enable location services on your device.'
        };

      case 'location-denied':
        return {
          primary:
            'Alcomy needs know to your location for certain security features to work',
          secondary: this.getPermissionDeniedInstructions()
        };
    }
  });

  protected map: google.maps.Map;

  #_effects = [
    effect(async () => {
      if (
        this.map ||
        !this.mapContainer() ||
        this.geolocationService.geolocation().status !== 'success' ||
        !this.currentFacility()?.geofence?.point
      ) {
        return;
      }

      await this.createMap();
    })
  ];

  protected logout(): void {
    this.store.dispatch(
      AuthenticationActions.logout({ userId: this.user()?.id })
    );
  }

  protected retry(): void {
    window.location.href = '/dashboard/overview';
  }

  protected changeFacility(facilityId: string) {
    this.auth.switchToFacility({ facilityId });
  }

  protected async openLocationSettings(): Promise<void> {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.Location,
      optionIOS: IOSSettings.LocationServices
    });
  }

  protected async openAppSettings(): Promise<void> {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App
    });
  }

  private getPermissionDeniedInstructions() {
    if (AppInfo.deviceInfo.platform !== 'web') {
      return 'Click "App Settings" below to grant permission for Alcomy to access your location.';
    }

    switch (this.getBrowser()) {
      case 'Chrome':
        return 'Go to Settings > Privacy and security > Site Settings > Permissions > Location and allow location access for this site.';

      case 'Firefox':
        return 'Click the address bar (site identity button) > Permission > Location and set to Allow.';

      case 'Safari':
        return 'Go to Safari > Preferences > Websites > Location and allow location access for this site.';

      case 'Edge':
        return 'Click the lock icon next to the URL in the address bar > Permissions > Location and set to Allow.';

      default:
        return 'Please check your browser settings to allow location access for this site.';
    }
  }

  private getBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes('Firefox')) {
      return 'Firefox';
    }

    if (userAgent.includes('Chrome')) {
      return 'Chrome';
    }

    if (userAgent.includes('Safari')) {
      return 'Safari';
    }

    if (userAgent.indexOf('Edge') > -1) {
      return 'Edge';
    }

    return 'Unknown';
  }

  private async createMap(): Promise<void> {
    try {
      const geofence = this.currentFacility().geofence;
      const userLocation = this.geolocationService.geolocation().location;

      const userLatLng = this.toLatLng(userLocation);
      const facilityLatLng = this.toLatLng(geofence.point);

      const loader = new Loader({ apiKey: environment.googleMapsWebApiKey });

      // We must load the libraries all at once with Promise.all to avoid a warning about importing multiple times
      const [{ Map, Circle }, { AdvancedMarkerElement }, { LatLngBounds }] =
        await Promise.all([
          loader.importLibrary('maps'),
          loader.importLibrary('marker'),
          loader.importLibrary('core')
        ]);

      this.map = new Map(this.mapContainer().nativeElement, {
        center: facilityLatLng,
        // This is the Google Maps ID for the custom style we use, it can be edited at https://mapstyle.withgoogle.com/
        mapId: '46f4fef0e69720a4',
        zoom: 17,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false
      });

      new AdvancedMarkerElement({
        map: this.map,
        position: facilityLatLng,
        title: 'Facility Location',
        content: await this.loadIcon('home_pin', Color.PRIMARY)
      });

      new Circle({
        map: this.map,
        center: facilityLatLng,
        radius: geofence.radius,
        strokeColor: Color.PRIMARY,
        strokeWeight: 2,
        fillColor: Color.PRIMARY,
        fillOpacity: 0.2
      });

      new AdvancedMarkerElement({
        map: this.map,
        position: userLatLng,
        title: 'User Location',
        content: await this.loadIcon('person_pin_circle', Color.DANGER)
      });

      new Circle({
        map: this.map,
        center: userLatLng,
        radius: userLocation.accuracy,
        strokeColor: Color.DANGER,
        strokeWeight: 2,
        fillColor: Color.DANGER,
        fillOpacity: 0.2
      });

      this.map.fitBounds(
        new LatLngBounds().extend(facilityLatLng).extend(userLatLng)
      );
    } catch (error) {
      Logger.error('Error creating map', { error });
    }
  }

  private toLatLng(geolocation: AlcGeolocation): google.maps.LatLngLiteral {
    return {
      lat: geolocation.latitude,
      lng: geolocation.longitude
    };
  }

  private async loadIcon(name: string, color: string): Promise<SVGSVGElement> {
    const icon = document.createElement('div');

    const response = await fetch(`assets/icon/material/${name}.svg`);
    icon.innerHTML = await response.text();

    const svg = icon.querySelector('svg');
    svg.setAttribute('class', 'fill-white h-14 w-12');

    const iconBackground = svg.querySelector('path:first-child');
    iconBackground.setAttribute('fill', color);
    iconBackground.setAttribute(
      'style',
      'transform-origin: center; transform: scale(1.3)  translate(0, 0.25px)'
    );

    return svg;
  }
}
