import { AuthenticationActions } from '$/app/authentication/authentication.actions';
import { AuthenticationService } from '$/app/authentication/authentication.service';
import { AppInfo } from '$/app/services';
import { SharedModule } from '$/app/shared/shared.module';
import { RouterSelectors } from '$/app/store';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { computedWith } from '$/lib/signals/computed-with';
import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { find } from 'lodash';

// TODO: What should we do if this page is simply reloaded?
// It appears that auth doesn't happen, and there's no facility, so... the page is kinda dysfunctional
@Component({
  selector: 'alc-ip-violation-page',
  imports: [SharedModule],
  templateUrl: './ip-violation.page.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AlcIpViolationPage {
  private readonly store = inject(Store);
  private readonly auth = inject(AuthenticationService);

  private readonly user = this.store.selectSignal(UserSelectors.selectUser);

  protected readonly currentFacility = computedWith(
    this.store.selectSignal(UserSelectors.selectFacilityId),
    (facilityId) => find(this.facilities(), { id: facilityId() })
  );

  protected readonly platform = AppInfo.deviceInfo.platform;

  protected readonly facilities = this.store.selectSignal(
    UserSelectors.selectUserFacilities
  );

  protected readonly ip = this.store.selectSignal(
    RouterSelectors.selectQueryParam('ip')
  );

  protected map: google.maps.Map;

  protected logout(): void {
    this.store.dispatch(
      AuthenticationActions.logout({ userId: this.user()?.id })
    );
  }

  protected retry(): void {
    window.location.href = '/dashboard/overview';
  }

  protected changeFacility(facilityId: string) {
    this.auth.switchToFacility({ facilityId });
  }
}
