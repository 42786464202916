<ion-header>
  <ion-toolbar [color]="'primary' | alcTheme">
    <ion-title>Network Violation</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="flex min-h-full items-center justify-center bg-surface p-6">
    <div class="flex max-w-lg flex-col items-center justify-center">
      @if (!map) {
        <alc-empty-placeholder>
          <alc-icon-container icon-size="150px">
            <ion-icon src="assets/icon/material/vpn_lock_2.svg" />
          </alc-icon-container>
        </alc-empty-placeholder>
      }

      <h1 class="text-center text-dark">
        Your IP address <b class="text-primary">{{ ip() }}</b> does not match
        any of your facility's allowed IP addresses.
      </h1>

      <p class="text-center text-lg text-medium-dark">
        Make sure you are connected to the facility's internet, that you are not
        connected to a VPN, and that your IP address is listed in the Allowed IP
        Addresses setting under Configuration > Settings > General > Location
        Settings.
      </p>

      <alc-callout type="warning">
        <p>
          Unless your facility's internet has been setup with a static IP
          address (i.e. one that never changes), your facility's IP will most
          likely change periodically, causing anyone who had access previously
          to lose it. This can be fixed by updating the facility's old IP
          address to the new one in the facility's Allowed IP Addresses setting.
        </p>

        <p>
          If you are getting this message while connected to the facility's
          internet, your facility's IP address is shown above; otherwise, you
          can do a Google search for "my ip address" while connected to the
          facility's internet. The IP address will be shown in a little widget
          called "What's my IP"
        </p>
      </alc-callout>

      <div class="mt-6 flex justify-center gap-4">
        <ion-button
          shape="round"
          [color]="'primary' | alcTheme"
          (click)="retry()"
        >
          Try Again
        </ion-button>

        <ion-button
          shape="round"
          [color]="'accent' | alcTheme"
          (click)="logout()"
        >
          Logout
        </ion-button>
      </div>

      <p class="my-6 text-xl sm:my-12">OR</p>

      <p class="mt-0 text-lg text-medium-dark">Try another facility below</p>

      @for (facility of facilities(); track facility.id) {
        <ion-item
          button
          lines="full"
          class="w-full"
          (click)="changeFacility(facility.id)"
        >
          <ion-icon slot="start" src="assets/icon/material/home.svg" />
          <ion-label>
            <h2>
              {{ facility.displayName || facility.name }}
            </h2>
            <p>
              {{ facility | address }}
            </p>
          </ion-label>
        </ion-item>
      }
    </div>
  </div>
</ion-content>
